.spaz-gif, img {
  position: relative;
}

.spaz-gif .spaz-button-share {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 6;
}

.spaz-gif .spaz-button-share svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
  stroke: #000;
}

.spaz-scroll .spaz-caption {
  background-color: rgb(0,0,0);
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 6px;
  z-index: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spaz-gif {
  background-color: #000;
  color: #fff;
  text-shadow: #000;
  display: inline-block;
}

img {
  z-index: 2;
  object-fit: cover;
}

.spaz-gif .spinner {
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 8px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  z-index: 1;
}

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}