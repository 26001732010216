.spaz-search-input {
  color: black;
}

.spaz-search-input .slds-button_icon svg {
  background-color: white;
}

.spaz-search-input .slds-combobox_object-switcher .slds-combobox__input {
  width: 9rem;
}

.spaz-search-input .slds-combobox_object-switcher .slds-combobox__input:focus {
  width: 11rem;
}