@media (max-width: 48em) {

  /* Smaller logo for mobile */
  .spaz-holyclip-logo {
    height: 75px;
    width: 150px;
    -webkit-filter: drop-shadow(1px 3px 3px #111);
    filter: drop-shadow(1px 3px 3px #111);
  }

  html,
  body {
    /* Disable outer most scrolling for mobile */
    height: 100%;
    overflow: hidden;
  }

  .spaz-holyclip-app .spaz-scrollable {
    -webkit-overflow-scrolling: touch;
  }

  .spaz-search-col {
    width: 100%;
  }
}

@media (min-width: 48em) {
  .spaz-holyclip-logo {
    height: 200px;
    width: 400px;
    -webkit-filter: drop-shadow(3px 5px 5px #111);
    filter: drop-shadow(3px 5px 5px #111);
  }

  .spaz-search-col {
    width: 80%;
  }
}

.spaz-holyclip-app {
  color: #ccc;
}

.spaz-holyclip-app input {
  color: black;
}

.spaz-holyclip-app A {
  color: #706e6b;
}

.slds-docked-form-footer svg {
  vertical-align: middle;
}

a.spaz-no-link-style:link {
  text-decoration: inherit;
  color: inherit;
}

a.spaz-no-link-style:visited {
  text-decoration: inherit;
  color: inherit;
}

.spaz-holyclip-logo-fg {
  text-align: right;
  line-height: 1em;
}

.spaz-holyclip-logo-bg * {
  position: relative;
  top: -10px;
}

.spaz-thanks-container {
  background-color: white;
}