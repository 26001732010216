.spaz-video {
  position: relative;
  display: flex;
  background-color: #000;
  color: #fff;
  text-shadow: #000;
  justify-content: center;
}

.spaz-video video {
  position: relative;
}

.spaz-video .spaz-button-mute {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 6;
}

.spaz-video .slds-button:active,
.spaz-video .slds-button:focus,
.spaz-video .slds-button:hover {
  color:#fff;
}

.spaz-video .spaz-button-mute svg {
  width: 2rem;
  height: 2rem;
  fill: #fff;
  stroke: #000;
}

.spaz-video .spaz-button-mute:hover, 
.spaz-video .spaz-button-mute:focus, 
.spaz-video .spaz-button-mute:visited, 
.spaz-video .spaz-button-mute:active {
  /* Safari desktop bug fix where button loses background on hover etc */
  border: inherit;
}

.spaz-video video, .spaz-video img {
  z-index: 3;
  /* visibility: hidden; */
}

.spaz-video .spinner {
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 8px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  z-index: 1;
}

.spaz-video .spaz-gl-spinner canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}