/* Oversize slide thumbs for mobile */
.spaz-gif-options input[type=range]::-ms-thumb {
  height: 25px;
  width: 25px;
}

.spaz-gif-options input[type=range]::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
}

.spaz-gif-options input[type=range]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius:50%;
}

.spaz-gif-options input[type=range]:disabled::-webkit-slider-thumb {
  -webkit-appearance: none;
  visibility: hidden;
} 

.spaz-gif-options input[type=range]:disabled::-moz-range-thumb {
  visibility: hidden;
}

.spaz-gif-options input[type=range]::-moz-range-track {
  background:transparent; 
  border:0px;
}

.spaz-gif-options input[type=text] {
  width: 100%;
}

.slds-modal__footer, .slds-modal__header {
  /* background: linear-gradient(217deg, rgba(26,35,41,0.75), rgba(26,35,41,1) 70.71%),
  linear-gradient(127deg, rgba(64,72,82,0.8), rgba(26,35,41,0) 70.71%),
    linear-gradient(336deg, rgba(46,57,68,0.8), rgba(61,71,82,0) 70.71%); */
  border: none;
}

.spaz-gif-options .spaz-align-right {
  text-align: right;
}

.spaz-gif-options .spaz-spinner {
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 8px solid #0070d2;
  background-color: none;
  border-right-color: transparent;
  display: inline-flex;
  border-radius: 50%;
  z-index: 1;
}

.spaz-left-slider.spaz-slider {
  /* Reverse left slider direction */
  direction: rtl;
}

.spaz-left-slider.spaz-slider .spaz-slider-caption.slds-truncate {
  /* Reverse left slider direction */
  direction: ltr;
}


.spaz-slider input {
  /* set custom slider style */
  border: solid 1px #908e8b;
  border-radius: 5px;
  height: 11px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.spaz-slider .spaz-slider-caption{
  width: 100;
  text-align: center;
  min-height: 1.25rem;
}


.spaz-gl-spinner canvas {
  width: 100%;
  height: 100%;
  /* display: block; */
}

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}