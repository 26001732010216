.spaz-about {
  text-align: center;
  background: #fff;
}

.spaz-about > h1 {
  font-size: 400%;
}

.spaz-about > h1 a {
  text-decoration: none;
  color: black;
}

.spaz-about .spaz-step-0 {
  color: #908e8b;
  background: linear-gradient(217deg, rgba(26,35,41,0.75), rgba(26,35,41,1) 70.71%),
linear-gradient(127deg, rgba(64,72,82,0.8), rgba(26,35,41,0) 70.71%),
  linear-gradient(336deg, rgba(46,57,68,0.8), rgba(61,71,82,0) 70.71%);
}

@media (max-width: 48em) {
  .spaz-about .spaz-step-0 video {
    width: 300px;
  }
  .spaz-about .spaz-step-05 video {
    width: 300px;
  }
}
@media (min-width: 48em) {
  .spaz-about .spaz-step-0 video {
    width: 600px;
  }
  .spaz-about .spaz-step-05 video {
    width: 300px;
  }
}

.spaz-about .spaz-step-1 {
  background: linear-gradient(rgb(217, 255, 223), rgb(189,245,154));
}


.spaz-about .spaz-step-3 .slds-is-absolute {
  z-index: 10;
}

.spaz-about .spaz-step-3 h2 {
  background-color: rgba(255,255,255,0.90);
  border:none;
  border-radius: 5px;
  text-shadow: 0px 0px 5px white;
}

.spaz-about .spaz-step-2 img {
  border:none;
  border-radius: 6px;
}

.spaz-about .spaz-icon-huge {
  height: 12rem;
  width: 12rem;
}
