.spaz-scroll {
  text-align: center;
}

.spaz-result-list.spaz-scroll .spaz-gif, .spaz-result-list.spaz-scroll img {
  height: 234px;
  width: 234px;
}

@media (max-width: 48em) {
  .spaz-result-list.spaz-scroll .spaz-gif, .spaz-result-list.spaz-scroll img {
    height: 170px;
    width: 170px;
    font-size: 75%;
  }
}
