.spaz-image-view .slds-modal__footer {
  /* Override modal footer text align */
  text-align: left;
  padding: 0;
  border: none;
}

.spaz-image-view .slds-modal__header {
  border: none;
}

.spaz-image-view .slds-modal__footer .slds-button + .slds-button {
  /* Override modal footer button margin */
  margin-left: 0px;
}

.spaz-hidden {
  display: none;
}

.spaz-nav-left {
  position: absolute;
  left: 0;
  margin-left: 0;
  z-index: 1000;
  bottom: 0;
  stroke: #000;
  color: #fff !important;
}

.slds-modal__close {
  stroke: #000 !important;
  color: #fff !important;
}

.spaz-nav-right {
  position: absolute;
  right: 0;
  margin-left: 0;
  z-index: 1000;
  bottom: 0;
  stroke: #000;
  color: #fff !important;
}

@media (max-height: 500px) {
  /* Don't display gif options if not enough height */
  .slds-modal__footer {
    display: none;
  }
}

@media (max-height: 800px) {
  .spaz-image-view .slds-modal__header .slds-modal__close {
    /* Position close button inside mobile to save space */
    top: 0.5rem;
    right: 0.5rem;
    z-index: 6;
    /* Give stroke so visible regardless of bg color */
    stroke: black;
  }
}
/* Desktop */
@media (min-width: 48em) {
  .spaz-nav-right {
    top: calc((428px + 55px) / 2);
  }
  .spaz-nav-left {
    top: calc((428px + 55px) / 2);
  }
  .slds-modal__container {
    width: 570px;
  }
  .slds-modal__content {
    min-height: 428px;
  }
  /* 2x image for larger displays */
  .slds-modal__container .spaz-holyclip-detail .spaz-video video,
  .slds-modal__container .spaz-holyclip-detail .spaz-video img {
    height: 428px;
    width: 570px;
  }
}

/* Tablet or twitter player */
@media (max-width: 48em) {
  .spaz-nav-right {
    top: calc((274px + 55px) / 2);
  }
  .spaz-nav-left {
    top: calc((274px + 55px) / 2);
  }
  .slds-modal__container {
    text-align: center;
  }
  .spaz-image-view .slds-modal__close {
    /* Position close button inside mobile to save space */
    top: 0.5rem;
    right: 0.5rem;
    z-index: 6;
    /* Give stroke so visible regardless of bg color */
    stroke: black;
  }
  .slds-modal__content {
    min-height: 274px;
    background-color: #000;
  }
  .slds-modal__container .spaz-holyclip-detail .spaz-video video,
  .slds-modal__container .spaz-holyclip-detail .spaz-video img {
    /* Small amount of zoom ~1.3x */
    height: 274px;
    width: 365px;
  }
}

/* Modern Mobile view */
@media (max-width: 415px) {
  .spaz-nav-right {
    top: calc((214px + 55px) / 2);
  }
  .spaz-nav-left {
    top: calc((214px + 55px) / 2);
  }
  .slds-modal__container {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }
  .spaz-holyclip-detail.slds-modal__content {
    min-height: 214px;
    min-width: 285px;
    background-color: #000;
  }
  .slds-modal__container .spaz-holyclip-detail .spaz-video video,
  .slds-modal__container .spaz-holyclip-detail .spaz-video img {
    height: 214px;
    width: 285px;
  }
}

/* Legacy Mobile view */
@media (max-width: 370px) {
  .slds-modal__container {
    margin-left: 0px;
    margin-right: 0px;
  }
}